<template>
  <div>
    <!-- 申请发起协议-->
    <el-dialog v-model="view.add" :fullscreen="true" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" center>
      <el-form label-width="120px" size="small" class="form">
        <div class="title">任务基本信息</div>
        <el-form-item label="任务主题" :required="true">
          <el-input v-model="form.Title" class="objectTitle"></el-input>
        </el-form-item>
      </el-form>

      <el-form label-width="120px" size="small" class="form">
        <div class="title">合同文件<span>(仅支持docx)</span></div>
        <el-row>
          <el-col :span="12">
            <el-form-item :required="true">
              <el-upload
                  class="upload-block"
                  accept=".docx"
                  :action='fileUrl'
                  :on-progress="openLoading"
                  :show-file-list="false"
                  :on-success="Taskfile">
                <el-button type="default" size="small">添加签署文件</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="form.TaskId">
              <i class="el-icon-circle-check"></i> 已上传
              <span class="fileSucces">{{ form.TaskId }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :required="true">
              <el-upload
                  class="upload-block"
                  accept=".docx"
                  :action='fileUrl'
                  :on-progress="openLoading"
                  :show-file-list="false"
                  :on-success="Ccfile">
                <el-button type="default" size="small">添加附件文件</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="form.CcId">
              <i class="el-icon-circle-check"></i> 已上传
              <span class="fileSucces">{{ form.CcId }}</span>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <el-form label-width="120px" size="small" class="form">
        <div class="title">任务参与人</div>
        <el-row :gutter="23">
          <el-col :span="12">
            <div class="blocks" @click="addTaskFirm">添加企业</div>
          </el-col>
          <el-col :span="12">
            <div class="blocks" @click="addTaskPersonal">添加个人</div>
          </el-col>
        </el-row>
        <div class="Participate" v-for="(item,index) in form.Task" :key="index">
          <div v-if="item.Type=='企业'">
            <div class="name">
              <div class="block is">参与类型</div>
              <div class="block">参与主体</div>
              <div class="block">负责人</div>
              <div class="block">手机号码</div>
            </div>
            <div class="data">
              <div class="block is">{{ item.Type }}</div>
              <div class="block">
                {{ item.Name }}
              </div>
              <div class="block">
                {{ item.UserName }}
              </div>
              <div class="block">
                {{ item.UserMobile }}
              </div>
              <div class="block it">
                <el-button size="small" type="text" @click="removeTask(index)">删除</el-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="name">
              <div class="block is">参与类型</div>
              <div class="block">参与人</div>
              <div class="block">手机号码</div>
            </div>
            <div class="data">
              <div class="block is">{{ item.Type }}</div>
              <div class="block">
                <el-autocomplete
                    v-model="item.UserName"
                    :fetch-suggestions="UserNameSearch"
                    placeholder="请输入姓名关键词"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                    @focus="handleSelectIndex(index)"
                ></el-autocomplete>
                <!--                <el-input v-model="item.UserName" size="small" class="t"></el-input>-->
              </div>
              <div class="block">
                <el-input v-model="item.UserMobile" size="small" class="t"></el-input>
              </div>
              <div class="block it">
                <el-button size="small" type="text" @click="removeTask(index)">删除</el-button>
              </div>
            </div>
          </div>

        </div>

      </el-form>
      <el-form label-width="120px" size="small" class="form">
        <div class="title">抄送人</div>
        <el-row :gutter="23">
          <el-col :span="12">
            <div class="blocks" @click=" addCcFirm">添加企业</div>
          </el-col>
          <el-col :span="12">
            <div class="blocks" @click="addCcPersonal">添加个人</div>
          </el-col>
        </el-row>
        <div class="Participate" v-for="(item,index) in form.CC" :key="index">
          <div v-if="item.Type=='企业'">
            <div class="name">
              <div class="block is">参与类型</div>
              <div class="block">参与主体</div>
              <div class="block">负责人</div>
              <div class="block">手机号码</div>
            </div>
            <div class="data">
              <div class="block is">{{ item.Type }}</div>
              <div class="block">
                {{ item.Name }}
              </div>
              <div class="block">
                {{ item.UserName }}
              </div>
              <div class="block">
                {{ item.UserMobile }}
              </div>
              <div class="block it">
                <el-button size="small" type="text" @click="removeCc(index)">删除</el-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="name">
              <div class="block is">参与类型</div>
              <div class="block">参与人</div>
              <div class="block">手机号码</div>
            </div>
            <div class="data">
              <div class="block is">{{ item.Type }}</div>
              <div class="block">
                <el-autocomplete
                    v-model="item.UserName"
                    :fetch-suggestions="UserNameSearch"
                    placeholder="请输入姓名关键词"
                    :trigger-on-focus="false"
                    @select="handleSelects"
                    @focus="handleSelectIndex(index)"
                ></el-autocomplete>
              </div>
              <div class="block">
                <el-input v-model="item.UserMobile" size="small" class="t"></el-input>
              </div>
              <div class="block it">
                <el-button size="small" type="text" @click="removeCc(index)">删除</el-button>
              </div>
            </div>
          </div>
        </div>

      </el-form>

      <template #footer>
    <span class="dialog-footer">
           <el-button @click="view.add = false" size="small">取 消</el-button>
           <el-button type="primary" @click="saveEdit" size="small">合同发起</el-button>
    </span>
      </template>
    </el-dialog>
    <el-dialog v-model="view.enterprise" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" width="1000px">
      <el-table :data="SignMechData">
        <el-table-column prop="Name" label="机构名称"></el-table-column>
        <el-table-column prop="IdNumber" label="社会代码"></el-table-column>
        <el-table-column prop="UserName" label="负责人"></el-table-column>
        <el-table-column prop="UserMobile" label="手机号码"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button
                type="text"
                @click="handleSign( scope.row)"
            >选中
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
    <span class="dialog-footer">
           <el-button @click="view.enterprise = false" size="small">取 消</el-button>
    </span>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import {fileAddSign,NameSearch} from '../api/Contract/index'
import {MechfetchData} from "../api/Sign";

export default {
  data() {
    return {
      QuerySelectIndex:0,
      //是否签署人与抄送人
      addStart: {
        //签署
        sign: true
      },
      //机构列表
      SignMechData: [],
      //上传文件路径
      TaskUrl: '',
      CcUrl: '',
      form: {
        //任务主题
        Title: '',
        //任务参与人
        Task: [],
        //抄送人
        CC: [],
        //签署ID
        TaskId: '',
        //附件ID
        CcId: ''
      },
      view: {
        add: false,
        enterprise: false
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleSelectIndex(index){
      this.QuerySelectIndex=index
    },
    handleSelects(item){
      let index=this.QuerySelectIndex
      let UserMobile=item.Mobile
      let UserName=item.Name
      this.form.CC[index].UserMobile=UserMobile
      this.form.CC[index].UserName=UserName
    },
    handleSelect(item) {
      let index=this.QuerySelectIndex
      let UserMobile=item.Mobile
      let UserName=item.Name
      this.form.Task[index].UserMobile=UserMobile
      this.form.Task[index].UserName=UserName
    },
    UserNameSearch(queryString, cb) {
      NameSearch({queryString}).then(res=>{
        let sum=res.sum
        let data=[]
        for (let item in sum){
          let Name=sum[item].Name
          let Mobile=sum[item].Mobile
          let add={value:Name+' '+Mobile,Name,Mobile}
          data.push(add)
        }
        cb(data)
      })

    },
    init() {
      this.fileUrl = process.env.VUE_APP_URL + 'Contract/file'
    },
    open() {
      this.form = {}
      this.view.add = true
      this.form.Task = []
      this.form.CC = []
      MechfetchData().then(res => {
        this.SignMechData = res.sum
      })
    },
    saveEdit() {
      let data = this.form
      if (!data.Title) {
        this.$message.error('未填写任务主题')
        return
      }
      if (!data.TaskId) {
        this.$message.error('未上传签署文件')
        return
      }

      if (data.Task.length == 0) {
        this.$message.error('任务参与人未添加')
        return;
      }
      let Task = data.Task
      for (let item in Task) {
        if (!Task[item].UserMobile || !Task[item].UserName) {
          this.$message.error('姓名或手机号码未填写，请检查任务参与人')
          return;
        }
        let UserMobile = JSON.parse(JSON.stringify(Task[item].UserMobile))
        //检查是否合法手机
        let Moble = /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(UserMobile)
        if (!Moble) {
          this.$message.error('手机号码不合法，请检查任务参与人')
          return;
        }
      }
      let CC = data.CC
      if (CC.length >= 1) {
        for (let item in CC) {
          if (!CC[item].UserMobile || !CC[item].UserName) {
            this.$message.error('姓名或手机号码未填写，请检查抄送人')
            return;
          }
          let UserMobile = JSON.parse(JSON.stringify(CC[item].UserMobile))
          //检查是否合法手机
          let Moble = /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(UserMobile)
          if (!Moble) {
            this.$message.error('手机号码不合法，请检查抄送人')
            return;
          }
        }
      }

      //检查附件文件是否存在
      // if (!data.Ccfile){
      //   this.$message.error('未上传附件文件')
      //   return
      // }
      if (data.Task.length == 0) {
        this.$message.error('未添加任务参与人')
        return
      }

      let UserInfo = this.$store.state.UserInfo

      let UserInfoMobile = UserInfo.Mobile
      let UserInfoName = UserInfo.Name
      let User_id = UserInfo.Admin_id

      let TaskId = data.TaskId
      let CcId = data.CcId
      //
      data.UserInfoMobile = UserInfoMobile
      data.UserInfoName = UserInfoName

      data.User_id = User_id
      data.TaskId = TaskId
      data.CcId = CcId

      const loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '生成合同中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });

      fileAddSign(data).then(res => {
        loading.close();
        if (res.code == 0) {
          this.view.add = false
          this.$message.success(res.message)
          this.$parent.getData();
        } else {
          this.$message.error(res.message);
        }
      })
    },
    addTaskFirm() {
      this.view.enterprise = true
      this.addStart.sign = true
      // this.form.Task.push({Name: '', Type: '企业', Mobile: ''})
    },
    handleSign(row) {
      let data = JSON.parse(JSON.stringify(row))
      this.view.enterprise = false
      if (this.addStart.sign == true) {
        this.form.Task.push({...data, Type: '企业'})
      } else {
        this.form.CC.push({...data, Type: '企业'})
      }

    },
    addTaskPersonal() {
      this.form.Task.push({UserName: '', Type: '个人', UserMobile: ''})
    },
    addCcFirm() {
      this.view.enterprise = true
      this.addStart.sign = false
    },
    addCcPersonal() {
      this.form.CC.push({UserName: '', Type: '个人', UserMobile: ''})
    },
    removeTask(index) {
      this.form.Task.splice(index, 1)
    },
    removeCc(index) {
      this.form.CC.splice(index, 1)
    },
    Taskfile(res) {
      this.closeLoading()
      if (res.code == 0) {
        let fileId = res.fileId
        this.form.TaskId = fileId
        this.$message.success(res.message)

      } else {
        this.$message.error(res.message)
      }
    },
    Ccfile(res) {
      this.closeLoading()
      if (res.code == 0) {
        let fileId = res.fileId
        this.form.CcId = fileId
        this.$message.success(res.message)

      } else {
        this.$message.error(res.message)
      }
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '上传中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },
    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.form {
  width: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px 30px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 2px 4px 0 rgb(0 0 0 / 6%);
  border-radius: 12px;
  margin-bottom: 20px;

  .objectTitle {
    width: 300px;
  }

  .Participate {
    width: 100%;
    height: 100px;
    margin-top: 20px;
    font-size: 13px;

    .name {
      width: 100%;
      height: 50px;
      background-color: #eff0f2;

      .block {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        color: #000000;
        width: 300px;
        text-indent: 1rem;
      }

      .is {
        width: 100px;
      }

    }

    .data {
      width: 100%;
      height: 50px;
      background-color: #fafafa;
      position: relative;

      .block {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        color: #000000;
        width: 300px;
        text-indent: 0.5rem;
      }

      .t {
        width: 200px;
      }

      .is {
        text-indent: 1rem;
        width: 100px;
      }

      .it {
        width: 40px;
        position: absolute;
        right: 40px;
      }
    }

  }

  .title {
    font-size: 14px;
    color: #000;
    padding: 20px;

    span {
      margin-left: 10px;
      font-size: 13px;
      color: #666;
    }
  }

  .fileSucces {
    color: #666666;
    font-size: 12px;
    margin-left: 10px;
  }

  .blocks {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: none;
    color: #ff725e;
    border: 1px dashed #ff725e;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
}

::v-deep .el-dialog {
  background-color: #f7f8fa;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: 100%;
    height: 100%;
    border: none;

    .el-upload {
      width: 100%;
      height: 100%;
    }

    .el-button {
      width: 100%;
      height: 100%;
      background: none;
      color: #ff725e;
      border: none;
    }
  }

  text-align: center;
  border: 1px dashed #ff725e;
  height: 40px;
  line-height: 40px;
}

.el-icon-circle-check {
  color: #00a854;
}
</style>
