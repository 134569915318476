<template>
  <div class="Contract">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 合同管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handladd" size="small">新增合同任务</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          size="small"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="合同名称" width="180"></el-table-column>
        <el-table-column prop="NewTime" label="时间" width="140"></el-table-column>
        <el-table-column label="参与人" width="290">
          <template #default="scope">
            <div v-for="(item,index) in scope.row.Task" :key="index" class="tegName">{{ item.UserName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="抄送人" width="290">
          <template #default="scope">
            <div v-for="(item,index) in scope.row.CC" :key="index" class="tegName">{{ item.UserName }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="UserInfoName" label="经办人"></el-table-column>
        <el-table-column label="合同文件">
          <template #default="scope">
            <el-button type="text" @click="openfile(scope.row.TaskId)">查看合同</el-button>
          </template>
        </el-table-column>
        <el-table-column label="附件">
          <template #default="scope">
            <el-button type="text" @click="openfile(scope.row.CcId)" v-if="scope.row.CcId">查看附件</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="Start" label="状态">
          <template #default="scope">
            <el-tag v-if="scope.row.Start=='已归档'" type="success">{{ scope.row.Start }}</el-tag>
            <el-tag v-else-if="scope.row.Start=='已撤销'" type="danger">{{ scope.row.Start }}</el-tag>
            <el-tag v-else type="warning">{{ scope.row.Start }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template #default="scope">
            <el-button size="small" type="text" v-if="scope.row.Start=='签署已发起'" @click="revoke(scope.row.FlowId
)">撤销发起
            </el-button>
            <el-button size="small" type="text" @click="confirm(scope.row.FlowId)" v-if="scope.row.Start=='签署已发起'">归档确认
            </el-button>
            <el-button size="small" type="text" @click="openflowId(scope.row.FlowId)" v-if="scope.row.Start=='已归档'">
              查看签署文档
            </el-button>
          </template>

        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handleUserPageChange"
        ></el-pagination>
      </div>
    </div>
    <SignContract ref="Sign"></SignContract>
  </div>
</template>
<script>
import {revokeSign, confirmFlowId, fetchData, openFileId, openFlowId} from '../../api/Contract/index'
import SignContract from '../../components/SignContract'

export default {
  mounted() {
    this.getData()
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      form: {},
      actionUrl: '',
      tableData: [],
      view: {
        add: true
      }
    }
  },
  components: {
    SignContract
  },
  methods: {
    //归档确认
    openflowId(FlowId) {
      this.openLoading()
      openFlowId({FlowId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.fileUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    confirm(FlowId) {
      // flowId
      this.$confirm("是否执行确认归档吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        confirmFlowId({FlowId}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch()

    },
    openfile(FileId) {
      this.openLoading()
      openFileId({FileId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.downloadUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    revoke(FlowId) {
      // flowId, _id
      this.$confirm("是否确认撤销吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        revokeSign({FlowId}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch()

    },
    handleUserPageChange(index) {
      this.query.pageIndex = index
      this.getData()
    },
    getData() {
      let UserInfo = this.$store.state.UserInfo
      let User_id = UserInfo.Admin_id

      this.loading = true
      let skip = this.query.pageIndex
      fetchData({skip, User_id}).then(res => {
        this.loading = false
        if (res.code == 0) {
          this.query.pageTotal = res.number
          this.tableData = res.sum;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handladd() {
      this.$refs.Sign.open()
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '加载中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },
    closeLoading() {
      this.loading.close();
    },
  }
}
</script>

<style scoped lang="scss">
.Contract {
  .handle-box {
    margin-bottom: 10px;
  }

  .Exist {
    cursor: pointer;
  }

  .NotExist {
    user-select: none;
  }

  .tegName {
    color: #666666;
    margin-right: 10px;
    display: inline;
  }


}


</style>
